var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"editHouseInfoReported"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"小区名称","prop":"communityId","rules":[
        { required: true, message: '请选择小区名称', trigger: 'blur' },
      ]}},[_c('v-select',{attrs:{"width":_vm.width,"options":_vm.communityList,"placeholder":"请选择"},on:{"change":_vm.handelSelectCommunity},model:{value:(_vm.form.communityId),callback:function ($$v) {_vm.$set(_vm.form, "communityId", $$v)},expression:"form.communityId"}})],1),_c('el-form-item',{attrs:{"label":"房间类型","prop":"type","rules":[
        { required: true, message: '请选择房间类型', trigger: 'blur' },
      ]}},[_c('v-select',{attrs:{"width":_vm.width,"options":_vm.roomTypeOps,"placeholder":"请选择"},on:{"change":_vm.handelSelectRoom},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1),(_vm.form.type == 1 || _vm.form.type == 2 || _vm.form.type == 3)?_c('el-form-item',{attrs:{"label":"幢名称","prop":"buildingName","rules":[{ required: true, message: '请输入幢名称', trigger: 'blur' }]}},[_c('v-input',{attrs:{"width":_vm.width},model:{value:(_vm.form.buildingName),callback:function ($$v) {_vm.$set(_vm.form, "buildingName", $$v)},expression:"form.buildingName"}})],1):_vm._e(),(_vm.form.type == 1 || _vm.form.type == 3 || _vm.form.type == 4)?_c('el-form-item',{attrs:{"label":"单元名称","prop":"unitName","rules":[
        { required: true, message: '请输入单元名称', trigger: 'blur' },
      ]}},[_c('v-input',{attrs:{"width":_vm.width},model:{value:(_vm.form.unitName),callback:function ($$v) {_vm.$set(_vm.form, "unitName", $$v)},expression:"form.unitName"}})],1):_vm._e(),(_vm.form.type == 1 || _vm.form.type == 2)?_c('el-form-item',{attrs:{"label":"房间名称","prop":"roomName","rules":[
        { required: true, message: '请输入房间名称', trigger: 'blur' },
      ]}},[_c('v-input',{attrs:{"width":_vm.width},model:{value:(_vm.form.roomName),callback:function ($$v) {_vm.$set(_vm.form, "roomName", $$v)},expression:"form.roomName"}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }