<template>
  <div class="editHouseInfoReported">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="小区名称"
        prop="communityId"
        :rules="[
          { required: true, message: '请选择小区名称', trigger: 'blur' },
        ]"
      >
        <v-select
          :width="width"
          :options="communityList"
          v-model="form.communityId"
          @change="handelSelectCommunity"
          placeholder="请选择"
        />
      </el-form-item>
      <el-form-item
        label="房间类型"
        prop="type"
        :rules="[
          { required: true, message: '请选择房间类型', trigger: 'blur' },
        ]"
      >
        <v-select
          :width="width"
          :options="roomTypeOps"
          v-model="form.type"
          @change="handelSelectRoom"
          placeholder="请选择"
        />
      </el-form-item>
      <el-form-item
        v-if="form.type == 1 || form.type == 2 || form.type == 3"
        label="幢名称"
        prop="buildingName"
        :rules="[{ required: true, message: '请输入幢名称', trigger: 'blur' }]"
      >
        <v-input :width="width" v-model="form.buildingName"></v-input>
      </el-form-item>
      <el-form-item
        v-if="form.type == 1 || form.type == 3 || form.type == 4"
        label="单元名称"
        prop="unitName"
        :rules="[
          { required: true, message: '请输入单元名称', trigger: 'blur' },
        ]"
      >
        <v-input :width="width" v-model="form.unitName"></v-input>
      </el-form-item>
      <el-form-item
        v-if="form.type == 1 || form.type == 2"
        label="房间名称"
        prop="roomName"
        :rules="[
          { required: true, message: '请输入房间名称', trigger: 'blur' },
        ]"
      >
        <v-input :width="width" v-model="form.roomName"></v-input>
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { roomTypeOps } from "./map.js";
import { communityListUrl, addAndReportedHouseInfo } from "./api.js";
import { _localStorage } from "@/utils/utils.js";
export default {
  name: "editHouseInfoReported",

  data() {
    return {
      width: 250,
      roomTypeOps: roomTypeOps(), //房间类型
      tenantId: "",
      communityList: [], //社区类型
      submitConfig: {
        submitUrl: addAndReportedHouseInfo,
      },
      form: {
        type: "", //房间类型
        communityId: "", //小区id
        buildingName: "", //幢名称
        unitName: "", //单元名称
        roomName: "", //房间名称
        optUserId: "", //操作人id
      },
    };
  },
  watch: {},
  created() {
    if (_localStorage.getItem("userInfo")) {
      let userInfo = JSON.parse(_localStorage.getItem("userInfo"));
      this.tenantId = userInfo.tenantId;
      this.form.optUserId = userInfo.id;
    }
    this.getCommunityList();
  },
  async mounted() {},
  methods: {
    //切换房间类型
    handelSelectRoom() {
      this.form.buildingName = "";
      this.form.unitName = "";
      this.form.roomName = "";
    },
    //选择房屋类型
    handelSelectCommunity(arg) {
      const curIndex = this.communityList.findIndex(
        (item) => item.value == arg
      );
      if (curIndex != -1) {
        this.form.communityName = this.communityList[curIndex].label;
      } else {
        this.form.communityName = "";
      }
    },
    //获取小区列表
    async getCommunityList() {
      let params = { tenantId: this.tenantId };
      let res = await this.$axios.get(`${communityListUrl}`, { params });
      if (res.code == 200) {
        this.communityList = [];
        if (res.data && res.data.records) {
          res.data.records.forEach((ele) => {
            let obj = {};
            obj.label = ele.name;
            obj.value = ele.spaceId;
            this.communityList.push(obj);
          });
        }
      }
    },
    submitBefore() {
      return true;
    },
    submitSuccess() {
      return true;
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
    },
  },
};
</script>
<style lang="less" scoped>
.editHouseInfoReported {
  box-sizing: border-box;
  height: 100%;
}
</style>
